@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: yekan;
  src: url("./sources/fonts/BYekan.ttf");
  font-weight: 400;
}
@font-face {
  font-family: yekan;
  src: url("./sources/fonts/BYekan-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: sahel;
  src: url("./sources/fonts/Sahel.ttf");
  font-weight: 400;
}
@font-face {
  font-family: sahel;
  src: url("./sources/fonts/Sahel-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Anton;
  src: url("./sources/fonts/Anton-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: Aquire;
  src: url("./sources/fonts/Aquire/Aquire-Box.otf");
}
* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
}
body {
  margin: 0;
  font-family: yekan, sahel, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h_iframe-aparat_embed_frame {
  position: relative;
  width: 100%;
  max-width: 440px;
  border-radius: 0.45rem;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: 100%;
}
.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
