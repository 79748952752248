[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-drag: element;
  -webkit-user-drag: element;
  -khtml-user-drag: element;
  user-drag: element;
}
svg {
  margin: 5px;
}
svg g {
  fill: hsl(var(--p));
  fill-opacity: 0.6;
}
svg g:hover {
  fill-opacity: 1;
}
svg g:hover > text {
  fill-opacity: 1;
}

svg g polygon {
  stroke: hsl(var(--p));
  stroke-width: 0.2;
  transition: fill-opacity 0.2s;
}
svg g text {
  font-size: 0.12rem;
  fill: #ffffff;
  fill-opacity: 0.4;
  transition: fill-opacity 0.2s;
}
svg path {
  fill: none;
  stroke: hsl(60, 20%, 70%);
  stroke-width: 0.4em;
  stroke-opacity: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
}
